import React, { useState } from 'react'
import {
  Fab,
  IconButton,
  Zoom,
  ThemeProvider,
  createTheme,
} from '@mui/material'
import { styled, keyframes } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneIcon from '@mui/icons-material/Phone'
import Instagram from '@mui/icons-material/Instagram'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { useLocation } from 'react-router-dom' // Import useLocation

const pulse = keyframes`
  0% { transform: scale(1); box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); }
  70% { transform: scale(1.05); box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
  100% { transform: scale(1); box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); }
`

const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 50,
  right: 50,
  zIndex: 1000,
}))

const MenuButton = styled(Fab)(({ theme }) => ({
  animation: `${pulse} 2s infinite`,
  width: 70,
  height: 70,
  background: 'linear-gradient(to bottom, #67c18c, #559f82)',
  '&:hover': { background: 'linear-gradient(to bottom, #b0ddc2, #d4ecde)' },
  position: 'relative',
  color: 'white',
}))

const RadialMenu = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: 250,
  height: 250,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

const MenuItem = styled('div')(({ theme }) => ({
  position: 'absolute',
  transition: 'all 0.3s ease',
  opacity: 0,
  visibility: 'hidden',
}))

const MenuItemButton = styled(IconButton)(({ theme }) => ({
  width: 55,
  height: 55,
  transition: 'all 0.25s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}))

const socialMediaLinks = [
  {
    icon: <LocationOnIcon />,
    url: 'https://maps.app.goo.gl/FDCny18D4evk3EpD6',
    color: '#2a7457',
  },
  { icon: <PhoneIcon />, url: 'tel:+966564217504"', color: '#67c18c' },
  {
    icon: <Instagram />,
    url: 'https://www.instagram.com/matrix.erp/',
    color: '#83c49d',
  },
]

const socialMediaLinksEYP = [
  {
    icon: <LocationOnIcon />,
    url: 'https://maps.app.goo.gl/FDCny18D4evk3EpD6',
    color: '#2a7457',
  },
  {
    icon: <WhatsAppIcon />,
    url: 'https://wa.me/message/U5TPCPL6PFPEG1',
    color: '#67c18c',
  },
  {
    icon: <Instagram />,
    url: 'https://www.instagram.com/matrix.erp/',
    color: '#83c49d',
  },
]

export default function ContactUsFloatingButton() {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation() // Get the current route

  // Determine which social media links to use based on the route
  const socialMedia =
    location.pathname === '/plans' ? socialMediaLinksEYP : socialMediaLinks

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleItemClick = url => {
    window.open(url, '_blank')
  }

  return (
    <>
      {isOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
          onClick={toggleMenu} // Close the menu when clicking outside
        />
      )}
      <Root>
        <MenuButton
          color="primary"
          aria-label="toggle menu"
          onClick={toggleMenu}
          sx={{
            backgroundColor: '#8ec9a6',
          }}
        >
          {isOpen ? (
            <CloseIcon />
          ) : (
            <>
              {/* Icons for closed state */}
              <LocationOnIcon
                sx={{
                  position: 'absolute',
                  top: '20%',
                  width: 16,
                  height: 16,
                }}
              />
              <PhoneIcon
                sx={{
                  position: 'absolute',
                  left: '20%',
                  top: '52%',
                  width: 16,
                  height: 16,
                }}
              />
              <Instagram
                sx={{
                  position: 'absolute',
                  right: '20%',
                  top: '52%',
                  width: 16,
                  height: 16,
                }}
              />
            </>
          )}
        </MenuButton>
        <RadialMenu style={{ display: isOpen ? 'block' : 'none' }}>
          {socialMedia.map((link, index) => {
            if (index === 1) {
              const angle = (index * 140 + 90) * (Math.PI / 180)
              const x = Math.cos(angle) * 100 + 60
              const y = Math.sin(angle) * 100 + 60
              return (
                <Zoom
                  in={isOpen}
                  key={index}
                  style={{
                    transitionDelay: isOpen ? `${index * 100}ms` : '0ms',
                  }}
                >
                  <MenuItem
                    style={{
                      transform: `translate(${isOpen ? x : 0}px, ${
                        isOpen ? y : 0
                      }px)`,
                      opacity: isOpen ? 1 : 0,
                      visibility: isOpen ? 'visible' : 'hidden',
                    }}
                  >
                    <MenuItemButton
                      style={{ backgroundColor: link.color }}
                      onClick={() => handleItemClick(link.url)}
                    >
                      {link.icon}
                    </MenuItemButton>
                  </MenuItem>
                </Zoom>
              )
            } else {
              const angle = (index * 140 + 90) * (Math.PI / 180)
              const x = Math.cos(angle) * 100
              const y = Math.sin(angle) * 100
              return (
                <Zoom
                  in={isOpen}
                  key={index}
                  style={{
                    transitionDelay: isOpen ? `${index * 100}ms` : '0ms',
                  }}
                >
                  <MenuItem
                    style={{
                      transform: `translate(${isOpen ? x : 0}px, ${
                        isOpen ? y : 0
                      }px)`,
                      opacity: isOpen ? 1 : 0,
                      visibility: isOpen ? 'visible' : 'hidden',
                    }}
                  >
                    <MenuItemButton
                      style={{ backgroundColor: link.color }}
                      onClick={() => handleItemClick(link.url)}
                    >
                      {link.icon}
                    </MenuItemButton>
                  </MenuItem>
                </Zoom>
              )
            }
          })}
        </RadialMenu>
      </Root>
    </>
  )
}
